import React from "react";
import "../styles/components/TextButton.scss";
import { ReactComponent as RefreshIcon } from "../assets/refresh.svg";
import Text from "./Text";

interface TextButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>{
  children: React.ReactNode;
  icon?: React.ReactNode;
  inverse?: boolean;
  disabled?: boolean;
}

const TextButton: React.FC<TextButtonProps> = ({
  onClick,
  children,
  icon = <RefreshIcon />,
  inverse = false,
  disabled = false,
  ...props
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event);
    }
    event.currentTarget.blur();
  };
  return (
    <button className={"text-button"} onClick={handleClick} disabled={disabled} {...props}>
      {icon && !inverse && <span className="text-button-icon">{icon}</span>}
      <Text styleType="style2">{children}</Text>
      {icon && inverse && <span className="text-button-icon">{icon}</span>}
    </button>
  );
};

export default TextButton;
