import Text from "./Text";
import { ReactComponent as Cross } from "../assets/cross.svg";

interface ChipInputProps {
  text: string;
  icon: React.ReactNode;
  id: number;
  onDelete: (id: number) => void;
}

const ChipInput: React.FC<ChipInputProps> = ({ text, icon, id, onDelete }) => {
  return (
    <div className="chip-input">
      {icon}
      <Text variant="h5" styleType="style2">
        {text}
      </Text>
      <Cross className="cross" onClick={() => onDelete(id)} />
    </div>
  );
};

export default ChipInput;
