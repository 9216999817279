import React, { createContext, useContext, useState, ReactNode } from "react";
import AlertModal from "../components/AlertModal";
import CustomModal from "../components/CustomModal";

interface ModalContextProps {
  showAlertModal: (
    mainText: string,
    secondaryText: string,
    onMainButton: () => void,
    mainButtonText: string,
    secondaryButtonText: string
  ) => void;
  showCustomModal: (content: ReactNode) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<"alert" | "custom">("alert");
  const [modalProps, setModalProps] = useState<any>({});

  const showAlertModal = (
    mainText: string,
    secondaryText: string,
    onMainButton: () => void,
    mainButtonText: string,
    secondaryButtonText: string
  ) => {
    setModalProps({
      mainText,
      secondaryText,
      onMainButton,
      mainButtonText,
      secondaryButtonText,
    });
    setModalType("alert");
    setIsModalOpen(true);
  };

  const showCustomModal = (content: ReactNode) => {
    setModalProps({ content });
    setModalType("custom");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalContext.Provider value={{ showAlertModal, showCustomModal, closeModal }}>
      {children}
      {isModalOpen && modalType === 'alert' && <AlertModal {...modalProps} onClose={closeModal} />}
      {isModalOpen && modalType === 'custom' && <CustomModal {...modalProps} onClose={closeModal} />}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextProps => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
