import React, { useEffect, useState } from "react";
import "../styles/RegistrationPage.scss";
import Text from "../components/Text";
import { useNavigate } from "react-router-dom";
import GoogleLoginButton from "../components/GoogleLoginButton";


const quotes = [
  {
    text: "One of the main advantages is ease of use. The Telegram interface is clean, intuitive, and well-organized. This allows for easy access to the necessary functions, both for new users and experienced ones.",
    author: "Vincent Obi",
  },
  {
    text: "The best way to predict the future is to invent it.",
    author: "Alan Kay",
  },
  {
    text: "Life is 10% what happens to us and 90% how we react to it.",
    author: "Charles R. Swindoll",
  },
];

const RegistrationPage: React.FC = () => {
  const navigate = useNavigate();
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(Math.floor(Math.random() * 3));
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
        setFade(true);
      }, 700); // Duration of fade out
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="registration-page">
      <div className="left-section">
        <Text variant="h1" styleType="style1" className="logo">
          logo
        </Text>
        <blockquote className={`quote ${fade ? "fade-in" : "fade-out"}`}>
          <Text variant="h3" styleType="style2" className="quote">
            “{quotes[currentQuoteIndex].text}”
          </Text>
          <Text variant="h2" styleType="style1" className="author">
            {quotes[currentQuoteIndex].author}
          </Text>
        </blockquote>
      </div>
      <div className="right-section">
        <Text variant="huge" styleType="style1">
          Get’s Started
        </Text>
        <Text variant="h4" styleType="style1">
          For the purpose of industry regulation, your details are required.
        </Text>
        <GoogleLoginButton/>
      </div>
    </div>
  );
};

export default RegistrationPage;
