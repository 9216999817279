import { gql } from "@apollo/client";

export const GET_USERS_SMM_PACK_RELATIONSHIPS = gql`
  query GetUsersSmmPackRelationships($relations: Boolean!) {
    usersSmmPackRelationships(relations: $relations) {
      smm_pack {
        id
        name
        language
        system
      }
    }
  }
`;

export const REMOVE_SMM_PACK = gql`
  mutation removeSmmPack($id: String!) {
    removeSmmPack(id: $id)
  }
`;

export const CREATE_SMM_PACK = gql`
  mutation CreateSmmPack($createSmmPackInput: CreateSmmPackInput!) {
    createSmmPack(createSmmPackInput: $createSmmPackInput) {
      id
      name
      language
      system
    }
  }
`;

export const UPDATE_SMM_PACK = gql`
  mutation UpdateSmmPack(
    $pack_id: String!
    $updateSmmPackInput: UpdateSmmPackInput!
  ) {
    updateSmmPack(pack_id: $pack_id, updateSmmPackInput: $updateSmmPackInput)
  }
`;

export const GENERATE_POST = gql`
  mutation GeneratePost($generatePostInput: GeneratePostInput!) {
    generatePost(generatePostInput: $generatePostInput) {
      id
      text
    }
  }
`;

export const GET_TELEGRAM_CHANNELS = gql`
  query TgChannelsBySmmPack($smm_pack_id: String!) {
    tgChannelsBySmmPack(smm_pack_id: $smm_pack_id) {
      id
      name
      username
    }
  }
`;

export const PUBLISH_POST = gql`
  mutation PublishPost($publishPostInput: PublishPostInput!) {
    publishPost(publishPostInput: $publishPostInput)
  }
`;
