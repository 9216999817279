import React, { ReactNode } from "react";
import Text from "./Text";
import "../styles/components/Button.scss";

type ButtonSize = "small" | "medium" | "big";

interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  icon?: ReactNode;
  size?: ButtonSize;
  children: React.ReactNode;
  contrast?: boolean;
  disabled?: boolean;
  reverse?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  icon,
  size = "medium",
  contrast = false,
  reverse = false,
  disabled,
  className,
  onClick,
  ...props
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event);
    }
    event.currentTarget.blur();
  };

  return (
    <button
      className={`${className} button ${size} ${contrast && "contrast"}`}
      onClick={handleClick}
      disabled={disabled}
      {...props}
    >
      {reverse && icon}
      <Text variant={size === "big" ? "h3" : "h4"} styleType="style1">
        {children}
      </Text>
      {!reverse && icon}
    </button>
  );
};

export default Button;
