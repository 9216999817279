import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface User {
  accessToken: string | null;
  userId: string | null;
  telegramId: string | null;
  telegramFirstName: string | null;
  fullName: string | null;
}

interface UserState {
  currentUser: User;
}

const initialState: UserState = {
  currentUser: localStorage.getItem("current_user")
    ? JSON.parse(localStorage.getItem("current_user")!)
    : { accessToken: null, user_id: null, telegramId: null, telegramFirstName: null, fullName: null},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
      localStorage.setItem("current_user", JSON.stringify(action.payload));
    },
    getUserData: (state) => {
      state.currentUser = JSON.parse(localStorage.getItem("current_user")!);
    },
    clearUserData: (state) => {
      state.currentUser = { accessToken: null, userId: null, telegramId: null, telegramFirstName: null, fullName: null};
      localStorage.removeItem("current_user");
    },
  },
});

export const { setUserData, clearUserData } = userSlice.actions;
export default userSlice.reducer;
