import "../styles/components/TextArea.scss";
import Text from "./Text";

interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label: string;
}

const TextArea: React.FC<TextAreaProps> = ({ label, id, ...props }) => {
  return (
    <div className="text-area">
      <Text variant="h5" styleType="style2">
        {label}
      </Text>
      <textarea
        id={id}
        {...props}
      ></textarea>
    </div>
  );
};

export default TextArea;
