import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

// Create an HTTP link to the GraphQL endpoint
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACK_URL}/graphql`,
});

// Middleware to add the authorization header
const authLink = setContext((_, { headers }) => {
  const token = JSON.parse(
    localStorage.getItem("current_user") ?? "{accessToken: null}"
  ).accessToken;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Create the Apollo Client with the authLink and httpLink
const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
