import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import packReducer from './packSlice';
import postReducer from './postSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    pack: packReducer,
    post: postReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
