import React from "react";
import "../styles/components/ChipButton.scss";
import Text from "./Text";
import { ReactComponent as PlusIcon } from "../assets/plus.svg";

interface ChipButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  label: string;
  icon?: React.ReactNode;
  onPress?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ChipButton: React.FC<ChipButtonProps> = ({
  label,
  icon = <PlusIcon />,
  onPress,
  className,
  ...props
}) => {
  return (
    <button className={`chip ${className}`} onClick={onPress} {...props}>
      <Text variant="h4" styleType="style2">
        {label}
      </Text>
      {icon && <span className="chip-icon">{icon}</span>}
    </button>
  );
};

export default ChipButton;
