import React, { useEffect, useState } from "react";
import { ReactComponent as RemoveSVG } from "../assets/remove.svg"; // Adjust the path to your SVG
import "../styles/components/Source.scss";

interface SourceProps {
  initialLink: string;
  onRemove: () => void;
  onUpdate: (newLink: string) => void;
  isEditing?: boolean;
}

const Source: React.FC<SourceProps> = ({
  initialLink,
  onRemove,
  onUpdate,
  isEditing,
}) => {
  const [editing, setEditing] = useState(false);
  const [link, setLink] = useState(initialLink);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (isEditing) {
      setEditing(true);
    }
  }, [isEditing]);

  const handleDoubleClick = () => {
    setEditing(true);
  };

  const handleBlur = () => {
    setEditing(false);
    onUpdate(link);
    setFocused(false);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  return (
    <div
      className={`source ${focused ? "focused" : ""}`}
      onClick={handleDoubleClick}
    >
      {editing ? (
        <input
          type="text"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          onBlur={handleBlur}
          onFocus={handleFocus}
          autoFocus
          placeholder="Enter a link"
          className="source-input"
        />
      ) : link !== "" ? (
        <span className="source-span">{link}</span>
      ) : (
        <span className="source-empty-span">Enter a link</span>
      )}
      <RemoveSVG className="remove-icon" onClick={onRemove} />
    </div>
  );
};

export default Source;
