import { Route, Routes } from "react-router-dom";
import RegistrationPage from "./pages/Registration";
import RedirectPage from "./pages/RedirectPage";
import Home from "./pages/Home/Home";
import { StrictMode } from "react";

function App() {
  return (
    <StrictMode>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sign-up" element={<RegistrationPage />} />
          <Route path="/redirect" element={<RedirectPage />} />
        </Routes>
      </div>
    </StrictMode>
  );
}

export default App;
