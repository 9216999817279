import { ReactComponent as TrashIcon } from "../assets/trash.svg";
import "../styles/components/SmmPackItem.scss";
import Text from "./Text";
import { useModal } from "../context/ModalContext";
import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { UPDATE_SMM_PACK } from "../graphql/queries";

interface SmmPackItemProps {
  id: string;
  name: string;
  isActive: boolean;
  onSetActive: (id: string) => void;
  onUpdate: () => void;
  onRemove: (id: string) => void;
  isEditing?: boolean;
}

const SmmPackItem: React.FC<SmmPackItemProps> = ({
  id,
  name,
  isActive,
  onSetActive,
  onUpdate,
  onRemove,
  isEditing: initialIsEditing = false,
}) => {
  const [isEditing, setIsEditing] = useState(initialIsEditing);
  const [packName, setPackName] = useState(name);
  const [updateSmmPack] = useMutation(UPDATE_SMM_PACK);

  const { showAlertModal } = useModal();

  const openModal = () => {
    showAlertModal(
      "Are you sure you want to delete this pack?",
      "After deleting, all information will be lost",
      () => onRemove(id),
      "Yes, Delete it!",
      "No, return"
    );
  };

  const handleDeleteClick = () => {
    openModal();
  };

  const handleSetActive = () => {
    onSetActive(id);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsEditing(false);
    try {
      await updateSmmPack({
        variables: {
          pack_id: id,
          updateSmmPackInput: {
            name: packName,
          },
        },
      });
      onUpdate();
    } catch (err) {
      console.error("Error updating SMM pack:", err);
      alert("An error occurred while trying to update the SMM pack.");
    }
  };

  useEffect(() => {
    setPackName(name);
  }, [name]);

  return (
    <div
      className={`smm-pack-item ${isActive ? "active" : ""}`}
      onClick={handleSetActive}
      onDoubleClick={() => setIsEditing(true)}
    >
      {isEditing ? (
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={packName}
            onChange={(e) => setPackName(e.target.value)}
            onBlur={() => setIsEditing(false)}
            autoFocus
          />
        </form>
      ) : (
        <Text variant="h4" styleType="style2">
          {name}
        </Text>
      )}
      <TrashIcon
        className="trash-icon"
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteClick();
        }}
      />
    </div>
  );
};

export default SmmPackItem;
