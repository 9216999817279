import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPostTo {
  sm: "telegram";
  id: string;
}

export interface SettingsState {
  system: string;
  positive_keys: string[];
  negative_keys: string[];
  language: string | null;
  post_to: IPostTo[];
  sources: string[];
}

interface SettingsStateWithID extends SettingsState {
  smm_pack_id: string;
}

export interface Post {
  post_id: string | null;
  smm_pack_id: string | null;
  prompt: string | null;
  prompt_button_disabled: boolean;
  post_text: string | null;
  post_text_button_disabled: boolean;
  settings?: SettingsState;
}

interface PostState {
  lastPosts: { [id: string]: Post };
}

export const defaultPost: Post = {
  post_id: null,
  smm_pack_id: null,
  prompt: null,
  prompt_button_disabled: true,
  post_text: null,
  post_text_button_disabled: true,
  settings: {
    system: "",
    positive_keys: [],
    negative_keys: [],
    language: "en",
    post_to: [],
    sources: [],
  },
};

const initialState = (): PostState => {
  const lastPosts: { [id: string]: Post } = {};
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.includes("last_post-")) {
      lastPosts[key.replace("last_post-", "")] = JSON.parse(
        localStorage.getItem(key)!
      );
      const storedPost = JSON.parse(localStorage.getItem(key)!);
      if (storedPost) {
        const updatedPost: Post = {
          ...defaultPost,
          ...storedPost,
        };
        lastPosts[key.replace("last_post-", "")] = updatedPost;
      }
    }
  }
  return { lastPosts };
};

const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    setLastPost: (state, action: PayloadAction<Post>) => {
      const post = action.payload;
      state.lastPosts[post.smm_pack_id!] = post;
      localStorage.setItem(
        `last_post-${action.payload.smm_pack_id}`,
        JSON.stringify(action.payload)
      );
    },
    setLastPostSettings: (
      state,
      action: PayloadAction<SettingsStateWithID>
    ) => {
      const { smm_pack_id, ...settings } = action.payload;
      state.lastPosts[smm_pack_id].settings = settings;
      localStorage.setItem(
        `last_post-${smm_pack_id}`,
        JSON.stringify(state.lastPosts[smm_pack_id])
      );
    },
    removePost: (state, action: PayloadAction<string>) => {
      delete state.lastPosts[action.payload];
      localStorage.removeItem(`last_post-${action.payload}`);
    },
  },
});

export const { setLastPost, setLastPostSettings, removePost } =
  postSlice.actions;
export default postSlice.reducer;
