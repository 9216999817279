import { useEffect, useRef, useState } from "react";
import ChipInput from "./ChipInput";
import "../styles/components/TextInput.scss";

interface ChipTextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  placeholder: string;
  defaultChips: string[] | null;
  onChipChange: (chips: string[]) => void;
  icon: React.ReactNode;
}

const ChipTextInput: React.FC<ChipTextInputProps> = ({
  label,
  id,
  icon,
  defaultChips = [],
  onChipChange,
  placeholder,
  ...props
}: ChipTextInputProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const [chips, setChips] = useState<string[]>(defaultChips!);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.includes(",") && e.target.value.length > 1) {
      setChips([...chips, e.target.value.replace(",", "").trim()]);
      onChipChange([...chips, e.target.value.replace(",", "")]);
      e.target.value = "";
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Backspace" &&
      chips.length > 0 &&
      e.currentTarget.value === ""
    ) {
      setChips(chips.slice(0, chips.length - 1));
      onChipChange && onChipChange(chips.slice(0, chips.length - 1));
    }
  };

  const handleChipDelete = (id: number) => {
    setChips(chips.filter((chip, index) => index !== id));
    onChipChange && onChipChange(chips.filter((chip, index) => index !== id));
  };

  const handleClickInside = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  useEffect(() => {
    if (defaultChips) {
      setChips(defaultChips);
    }
  }, [defaultChips]);

  return (
    <div className="text-container">
      <label htmlFor={id}>{label}</label>
      <div className="text-input" onClick={handleClickInside}>
        <div className="chip-container" style={chips && chips.length > 0 ? {marginBottom: '8px'}:{}}>
          {chips &&
            chips.map((chip, index) => (
              <ChipInput
                key={index}
                id={index}
                text={chip}
                icon={icon}
                onDelete={() => handleChipDelete(index)}
              />
            ))}
        </div>
        <input
            ref={ref}
            type="text"
            onChange={handleChange}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            {...props}
          />
      </div>
    </div>
  );
};

export default ChipTextInput;
