import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useQuery } from "@apollo/client";
import { GET_TELEGRAM_CHANNELS } from "../../graphql/queries";
import { ReactComponent as RefreshSVG } from "../../assets/refresh.svg";
import { ReactComponent as PlusSVG } from "../../assets/plus.svg";
import { useEffect, useState } from "react";
import Text from "../../components/Text";
import "../../styles/HomePageStyles/TelegramChannels.scss";
import { IPostTo } from "../../store/postSlice";

interface TelegramChannelsProps {
  onNewPostToChange: (newPostTo: IPostTo[]) => void;
  defaultPostTo: IPostTo[];
}

const TelegramChannels: React.FC<TelegramChannelsProps> = ({
  onNewPostToChange,
  defaultPostTo,
}: TelegramChannelsProps) => {
  const activePack = useSelector((state: RootState) => state.pack.activePack);
  const currentUser = useSelector((state: RootState) => state.user.currentUser);

  const [newPostTo, setNewPostTo] = useState<IPostTo[]>(defaultPostTo);

  const { loading, error, data, refetch } = useQuery(GET_TELEGRAM_CHANNELS, {
    variables: { smm_pack_id: activePack.id },
  });

  const startConversation = () => {
    if (!currentUser.telegramId)
      return alert("Please connect your Telegram account first");
    fetch(
      `${process.env.REACT_APP_BACK_URL}/telegram-bot/start-conversation`,
      {
        method: "POST",
        body: JSON.stringify({ telegram_id: currentUser.telegramId }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Conversation started")
          window.open("https://t.me/telaigram_bot");
        else
          alert(
            "An error occurred while starting the conversation. Please try again later."
          );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSet = (e: any) => {
    const channelId = e.target.value;
    const existingChannelIndex = newPostTo.findIndex(
      (value) => value.id === channelId
    );
    if (existingChannelIndex !== -1) {
      // Remove channel from newPostTo
      const updatedPostTo = [...newPostTo];
      updatedPostTo.splice(existingChannelIndex, 1);
      setNewPostTo(updatedPostTo);
    } else {
      // Add channel to newPostTo
      const channel = data.tgChannelsBySmmPack.find(
        (channel: { id: number }) => channel.id.toString() === channelId
      );
      if (channel) {
        const channelToAdd = {
          sm: "telegram",
          id: channel.id.toString(),
        } as IPostTo;
        const updatedPostTo = [...newPostTo, channelToAdd];
        setNewPostTo(updatedPostTo);
      }
    }
  };

  useEffect(() => {
    onNewPostToChange(newPostTo);
  }, [newPostTo]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error</div>;

  return (
    <div className="telegram-channels">
      <div className="tgc-header">
        <Text className="tgc-title">Telegram Channels</Text>
        <div className="tgc-actions">
          {/* // TODO  add animation of the arrow rotating so the person can understand it is actually working */}
          <RefreshSVG onClick={() => refetch()}>Refresh</RefreshSVG>
          <PlusSVG
            className={currentUser.telegramId ? "" : "disabled"}
            onClick={startConversation}
          >
            Add Channel
          </PlusSVG>
        </div>
      </div>
      <div className="tgc-body">
        {data.tgChannelsBySmmPack.length > 0 ? (
          data.tgChannelsBySmmPack.map(
            (channel: { id: number; name: string; username: string }) => (
              <div key={channel.id.toString()}>
                <label className="tgc-input">
                  <input
                    type="checkbox"
                    id={channel.id.toString()}
                    value={channel.id.toString()}
                    name={channel.id.toString()}
                    checked={
                      newPostTo.filter(
                        (value) => value.id === channel.id.toString()
                      ).length > 0
                    }
                    onChange={handleSet}
                  />
                  <span className="checkbox"></span>
                  <span className="checkbox-label">{channel.name}</span>
                </label>
              </div>
            )
          )
        ) : (
          <Text className="no-channels" onClick={startConversation}>
            Add a new channel
          </Text>
        )}
      </div>
    </div>
  );
};

export default TelegramChannels;
