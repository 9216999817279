import { useEffect, useRef, useState } from "react";
import "../styles/components/TextInput.scss";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  placeholder: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  onChange,
  placeholder,
  defaultValue,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  };

  const handleClickInside = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  useEffect(() => {
    setValue(defaultValue ?? "");
  }, [defaultValue]);

  return (
    <div className="text-container">
      <label htmlFor={props.id}>{props.label}</label>
      <div className="text-input" onClick={handleClickInside}>
        <input
          ref={ref}
          type="text"
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          {...props}
        />
      </div>
    </div>
  );
};

export default TextInput;
