import { useEffect, useState } from "react";
import ChipButton from "../../components/ChipButton";
import Source from "../../components/Source";
import "../../styles/HomePageStyles/Sources.scss";

interface SourcesProps {
  defaultSources: string[];
  onNewSetOfLinks: (links: string[]) => void;
}

const Sources: React.FC<SourcesProps> = ({
  defaultSources,
  onNewSetOfLinks,
}) => {
  const [sources, setSources] = useState(defaultSources || []);
  const [newSourceIndex, setNewSourceIndex] = useState<number | null>(null);

  const handleAddSource = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (sources.some((source) => source === "")) {
      // TODO shake or somehow show that there is an empty source, and scroll to it if needed
      
      return;
    }
    setSources([...sources, ""]);
    setNewSourceIndex(sources.length);
  };

  const handleRemoveSource = (index: number) => {
    setSources(sources.filter((_, i) => i !== index));
    if (index === newSourceIndex) {
      setNewSourceIndex(null);
    }
  };

  const handleUpdateSource = (index: number, newLink: string) => {
    const updatedSources = [...sources];
    updatedSources[index] = newLink;
    setSources(updatedSources);
    onNewSetOfLinks(updatedSources);
    if (index === newSourceIndex) {
      setNewSourceIndex(null);
    }
  };

  useEffect(() => {
    setSources(defaultSources || []);
  }, [defaultSources]);

  return (
    <div className="sources-container">
      <ChipButton label="Add Source" onPress={handleAddSource} />
      {sources.map((source, index) => (
        <Source
          key={index}
          initialLink={source}
          onRemove={() => handleRemoveSource(index)}
          onUpdate={(newLink) => handleUpdateSource(index, newLink)}
          isEditing={index === newSourceIndex}
        />
      ))}
    </div>
  );
};

export default Sources;
