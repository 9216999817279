import React, { useEffect } from "react";
import "../styles/components/AlertModal.scss";
import Text from "./Text";
import Button from "./Button";
import TextButton from "./TextButton";
import { ReactComponent as CrossIcon } from "../assets/cross.svg";
import { ReactComponent as BlockIcon } from "../assets/block.svg";

interface AlertModalProps {
  mainText: string;
  secondaryText: string;
  onMainButton: () => void;
  mainButtonText: string;
  secondaryButtonText: string;
  onClose: () => void;
}

const AlertModal: React.FC<AlertModalProps> = ({
  mainText,
  secondaryText,
  onMainButton,
  mainButtonText,
  secondaryButtonText,
  onClose,
}) => {
  const handleMainClick = () => {
    onMainButton();
    onClose();
  };
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.closest(".modal-content") === null) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onClose]);

  return (
    <div className="modal">
      <form id="alert-modal">
        <div className="modal-content">
          <div className="modal-main">
            <CrossIcon onClick={onClose} />
            <Text variant="h1">{mainText}</Text>
            <Text variant="h3" styleType="style2">
              {secondaryText}
            </Text>
          </div>
          <div className="vertical-bar"></div>
          <div className="modal-footer">
            <TextButton
              onClick={onClose}
              inverse
              icon={<BlockIcon />}
              type="button"
            >
              {secondaryButtonText}
            </TextButton>
            <Button
              type="submit"
              form="alert-modal"
              contrast
              onClick={handleMainClick}
            >
              {mainButtonText}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AlertModal;
