import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import "../../styles/HomePageStyles/Home.scss";
import { useNavigate } from "react-router-dom";
import TextArea from "../../components/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Text from "../../components/Text";
import Button from "../../components/Button";
import { ReactComponent as MagicSVG } from "../../assets/magic.svg";
import { ReactComponent as PublishSVG } from "../../assets/publish.svg";
import { useMutation } from "@apollo/client";
import { GENERATE_POST, PUBLISH_POST } from "../../graphql/queries";
import { ReactComponent as SettingsSVG } from "../../assets/settings.svg";
import {
  Post,
  SettingsState,
  defaultPost,
  setLastPost,
} from "../../store/postSlice";
import { selectPostById } from "../../store/selectors";
import Settings from "./Settings";
import { Flip, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as SuccessSVG } from "../../assets/success.svg";
import { clearUserData } from "../../store/userSlice";

const Home: React.FC = () => {
  // TODO add when the user logs in for the first time, the post he sees should be the last one unpublished from the smm pack
  const dispatch = useDispatch();

  // Navigate to the sign-up page if the user is not logged in
  const userState = useSelector((state: RootState) => state.user.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (userState.accessToken === null || userState.userId === null) {
      dispatch(clearUserData());
      navigate("/sign-up");
    }
  }, [navigate]);

  const [settingsState, setSettingsState] = useState(true);

  const activePack = useSelector((state: RootState) => state.pack.activePack);
  const pp = useSelector((state: RootState) =>
    selectPostById(state, activePack.id)
  );
  const [lastPostState, setLastPostState] = useState<Post>(
    useSelector((state: RootState) => selectPostById(state, activePack.id))
  );

  const [generatePost, { data }] = useMutation(GENERATE_POST);
  const [publishPostMutation, { data: publishPostData }] =
    useMutation(PUBLISH_POST);

  useEffect(() => {
    setLastPostState(pp ?? defaultPost);
  }, [activePack.id]);

  useEffect(() => {
    if (lastPostState && lastPostState.smm_pack_id) {
      dispatch(setLastPost(lastPostState));
    }
  }, [lastPostState]);

  // update the last post in the store when a new post is created
  useEffect(() => {
    if (data) {
      setLastPostState({
        ...lastPostState,
        post_text: data.generatePost.text,
        post_id: data.generatePost.id,
      });
      setButtonDisability({
        ...lastPostState,
        post_text: data.generatePost.text,
        post_id: data.generatePost.id,
      });
    }
  }, [data]);

  useEffect(() => {
    if (publishPostData === undefined) return;
    if (publishPostData) {
      toast.success("Your post hase been published successfully", {
        icon: <SuccessSVG />,
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Flip,
      });
      setLastPostState({ ...defaultPost, settings: lastPostState.settings });
      dispatch(
        setLastPost({
          ...defaultPost,
          smm_pack_id: activePack.id,
          settings: lastPostState.settings,
        })
      );
    } else {
      toast.error("An error occurred while publishing your post", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Flip,
      });
    }
  }, [publishPostData]);

  const handleSaveSettings = (settings: SettingsState) => {
    setLastPostState({ ...lastPostState, settings });
    setButtonDisability({ ...lastPostState, settings });
  };

  const toggleSettingsState = () => setSettingsState(!settingsState);

  const setButtonDisability = (newPostState: Post) => {
    if (newPostState.prompt === null || newPostState.prompt === "") {
      newPostState["prompt_button_disabled"] = true;
    } else {
      newPostState["prompt_button_disabled"] = false;
    }
    if (
      newPostState.post_text === null ||
      newPostState.post_text === "" ||
      newPostState.settings?.post_to.length === 0
    ) {
      newPostState["post_text_button_disabled"] = true;
    } else {
      newPostState["post_text_button_disabled"] = false;
    }
    setLastPostState(newPostState);
  };

  const handlePromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newPostState = { ...lastPostState, prompt: e.target.value };
    setLastPostState({
      ...lastPostState,
      prompt: e.target.value,
    });
    setButtonDisability(newPostState);
  };

  const handleGenerateTetx = async () => {
    const { prompt, post_id, settings } = lastPostState;
    if (prompt === null || prompt === "") {
      alert("Please enter a prompt");
    } else {
      try {
        if (post_id == null) {
          await generatePost({
            variables: {
              generatePostInput: {
                prompt: prompt,
                type: "generate",
                smm_pack_id: activePack.id,
                settings: settings,
              },
            },
          });
        } else {
          await generatePost({
            variables: {
              generatePostInput: {
                prompt: prompt,
                type: "regenerate",
                post_id: post_id,
                settings: settings,
              },
            },
          });
        }
      } catch (err: any) {
        console.error("Error creating post:", err.message);
      }
    }
  };

  const handlePostTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLastPostState({
      ...lastPostState,
      post_text: e.target.value,
    });
    setButtonDisability({ ...lastPostState, post_text: e.target.value });
  };

  const publishPost = async () => {
    try {
      await publishPostMutation({
        variables: {
          publishPostInput: {
            text: lastPostState.post_text,
            post_id: lastPostState.post_id,
            settings: lastPostState.settings,
            smm_pack_id: activePack.id,
          },
        },
      });
    } catch (err: any) {
      console.error("Error publishing post:", err.message);
    }
  };

  return (
    <>
      <div className="home">
        <SideBar />
        {activePack.id ? (
          <div className="main">
            <Settings
              closed={settingsState}
              onSave={handleSaveSettings}
              settings={lastPostState.settings!}
            />
            <div className="main-container">
              <div className="main-container-content">
                <div className="settings-svg">
                  <SettingsSVG onClick={toggleSettingsState} />
                </div>
                <Text variant="huge" className="title">
                  {activePack.name}
                </Text>
                <div className="prompt-area">
                  <TextArea
                    label="Prompt text"
                    id="prompt"
                    placeholder="Prompt..."
                    onChange={handlePromptChange}
                    value={lastPostState.prompt ?? ""}
                  />
                  <Button
                    reverse
                    icon={<MagicSVG />}
                    disabled={lastPostState.prompt_button_disabled}
                    onClick={handleGenerateTetx}
                  >
                    Generate Text
                  </Button>
                </div>
                <div className="post-area">
                  <TextArea
                    label="Post text"
                    className="post-area-text"
                    id="text"
                    placeholder="Text..."
                    onChange={handlePostTextChange}
                    value={lastPostState.post_text ?? ""}
                  />
                </div>
                <div className="footer">
                  <div className="social-posts"></div>
                  <Button
                    contrast
                    icon={<PublishSVG />}
                    disabled={lastPostState.post_text_button_disabled}
                    onClick={publishPost}
                  >
                    Publish
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>Create a new Pack</div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default Home;
