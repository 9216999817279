import { useDispatch, useSelector } from "react-redux";
import Text from "../../components/Text";
import TextInput from "../../components/TextInput";
import { RootState } from "../../store";
import "../../styles/HomePageStyles/Settings.scss";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import {
  IPostTo,
  SettingsState,
  setLastPostSettings,
} from "../../store/postSlice";
import { ReactComponent as TickSvg } from "../../assets/tick.svg";
import { ReactComponent as SaveSVG } from "../../assets/save.svg";
import TelegramChannels from "./TelegramChannels";
import ChipTextInput from "../../components/ChipTextInput";
import Sources from "./Sources";

interface SettingsProps {
  closed: boolean;
  settings: SettingsState;
  onSave: (settings: SettingsState) => void;
}

const Settings: React.FC<SettingsProps> = ({ closed, onSave, settings }) => {
  const dispatch = useDispatch();
  const activePack = useSelector((state: RootState) => state.pack.activePack);

  const [settingsState, setSettingsState] = useState<SettingsState>(settings);

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const handleSystemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSettingsState({ ...settingsState, system: e.target.value });
  };
  const handleChipChange = (
    chips: string[],
    keys: "positive_keys" | "negative_keys"
  ) => {
    setSettingsState({ ...settingsState, [keys]: chips });
  };
  const handleNewPostToChange = (post_to: IPostTo[]) => {
    setSettingsState({ ...settingsState, post_to });
  };
  const handleNewSources = (sources: string[]) => {
    setSettingsState({ ...settingsState, sources });
  };
  const handleSave = () => {
    dispatch(
      setLastPostSettings({ ...settingsState, smm_pack_id: activePack.id! })
    );
    onSave({ ...settingsState });
    setSaveButtonDisabled(true);
  };

  useEffect(() => {
    setSettingsState(settings);
  }, [settings]);

  useEffect(() => {
    const shallowEqual = (obj1: any, obj2: any): boolean => {
      if (obj1 === obj2) return true;

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) return false;

      for (let key of keys1) {
        if (key === "post_to") {
          if (obj1[key].toString() !== obj2[key].toString()) return false;
        } else if (obj1[key] !== obj2[key]) return false;
      }

      return true;
    };

    !shallowEqual(settingsState, settings)
      ? setSaveButtonDisabled(false)
      : setSaveButtonDisabled(true);
  }, [settingsState]);

  return (
    <div className={`settings ${closed ? "closed" : ""}`}>
      {!closed && (
        <form id="settings" className="settings-form">
          <Text className="settings-name" variant="h2">
            Settings
          </Text>
          <TelegramChannels
            onNewPostToChange={(post_to) => handleNewPostToChange(post_to)}
            defaultPostTo={settingsState.post_to}
          />
          <Sources defaultSources={settingsState.sources} onNewSetOfLinks={handleNewSources} />
          <TextInput
            label="System"
            id="system"
            placeholder="e.g. polite blogger tone"
            defaultValue={settingsState.system}
            onChange={handleSystemChange}
          />
          <ChipTextInput
            label="Positive Keys"
            id="positive_keys"
            placeholder="positive,interesting,.."
            defaultChips={settingsState.positive_keys}
            onChipChange={(chips: string[]) =>
              handleChipChange(chips, "positive_keys")
            }
            icon={<TickSvg />}
          />
          <ChipTextInput
            label="Negative Keys"
            id="negative_keys"
            placeholder="negative,boring,..."
            defaultChips={settingsState.negative_keys}
            onChipChange={(chips: string[]) =>
              handleChipChange(chips, "negative_keys")
            }
            icon={<TickSvg />}
          />
          <Button
            disabled={saveButtonDisabled}
            icon={<SaveSVG />}
            onClick={handleSave}
            type="submit"
            form="settings"
          >
            Save
          </Button>
        </form>
      )}
    </div>
  );
};

export default Settings;
