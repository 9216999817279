import React from 'react';
import '../styles/components/Text.scss';

interface TextProps extends React.HTMLAttributes<HTMLParagraphElement>{
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'huge';
  styleType?: 'style1' | 'style2';
  children: React.ReactNode;
}

const Text: React.FC<TextProps> = ({ variant='h4', styleType = 'style1', className, children, ...props }) => {
  return (
    <p className={`text ${variant} ${styleType} ${className ?? ''}`} {...props}>
      {children}
    </p>
  );
};

export default Text;
