import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setUserData } from "../store/userSlice";

const RedirectPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const accessToken = params.get("access_token");
    const userId = params.get("user_id");
    const telegramId = params.get("telegram_id");
    const telegramFirstName = params.get("telegram_first_name");
    const fullName = params.get("full_name");

    if (accessToken) {
      dispatch(
        setUserData({
          accessToken,
          userId,
          telegramId,
          telegramFirstName,
          fullName,
        })
      );
      navigate("/", { replace: true });
    } else {
      setError("An Error Occurred, try again later...");
      setTimeout(() => {
        navigate("/sign-up");
      }, 3000);
    }
  }, [location, navigate]);

  return (
    <div className="redirect-page">
      {error ? <p>{error}</p> : <p>Redirecting...</p>}
    </div>
  );
};

export default RedirectPage;
