import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UPDATE_SMM_PACK } from "../graphql/queries";
import client from "../ApolloClient";

interface Pack {
  id: string | null;
  name: string | null;
}

interface UpdatePackProps {
  name?: string;
}

interface PackState {
  activePack: Pack;
}

const initialState: PackState = {
  activePack: localStorage.getItem("active_pack")
    ? JSON.parse(localStorage.getItem("active_pack")!)
    : { id: null, name: "", system: "", language: "" },
};

const packSlice = createSlice({
  name: "pack",
  initialState,
  reducers: {
    setActivePack: (state, action: PayloadAction<Pack>) => {
      state.activePack = action.payload;
      if (action.payload.id) {
        localStorage.setItem("active_pack", JSON.stringify(action.payload));
      } else {
        localStorage.removeItem("active_pack");
      }
    },
    updateActivePack: (state, action: PayloadAction<UpdatePackProps>) => {
      state.activePack = { ...state.activePack, ...action.payload };
      client.mutate({
        mutation: UPDATE_SMM_PACK,
        variables: {
          pack_id: state.activePack.id,
          updateSmmPackInput: action.payload,
        },
      });
      localStorage.setItem("active_pack", JSON.stringify(state.activePack));
    },
  },
});

export const { setActivePack, updateActivePack } = packSlice.actions;
export default packSlice.reducer;
